@import url("normalize");

:root {
  --termgreen: #25da4e;
  --termgreen-darker: #1a9937;
  --termgreen-bit-darker: #1eae3e;
  --termgreen-lighter: #51e171;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

a {
  color: var(--termgreen-lighter);
  text-decoration: none;
}

dd {
  color: var(--termgreen-bit-darker);
}

img {
  width: 100%;
  height: auto;
}

html {
  color: var(--termgreen);
  background: #222;
  background:
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAQAAAAziH6sAAAEEWlDQ1BJQ0MgUHJvZmlsZQAAGFeNT01vG1UUPc48O0gJs0ClKS4KIz7aqEoip0UlVmhaO06dL1LLSUoSIaHx+DkePLGHmXGaRll1A7sW+AFA6IIFEiukQkG0m7YLUCpVAUVElZC6SMSHKip1gyJz34zjmQiD+kYz78y55557LtD6uWqaRosCLJYdK5tOKrNz80rrJlrwHNoQRZuq2WYik5kAHaEV9/7z+CeExH2vp3n9f09bntsaEHqKcCVva4uEl4GwoZmWA0QeEN9/wTEJtz5P+IBFAQnHBF7wcErgnIdnXc10doiwyCJrRTVPeJVwdy7ALwSwl8E9B9K8zC1dU7LppJKxKgXd4PXSE5Sf8Cwa1b15nfS226Wpcbq7gNBy0RrJ1vEnmjo8RfhlwndMJyn4I4R/r5ZmEoSPAS3PFKyzM56+5fWV4vSbhA8SzuvO6HSdXynnJs95vS1rpcq48BGaO5o9NE/4RcL3i3x0wssjIc9Tw4S7CXcWqyN1f2nMXpoSvOuzUhya9Hwk6x11LEM4Svh9q5IVsyiztMaNtJhF/tIt08nUM0jbZWNSzCJPJnPb3dHlneL0iNfLTjrWdL2XzRf0s6N1/appZEQvZWNXrWpWZBOau6o1nPZ82F+8PFP3DB/Kq6lxwr2EB3E+pIKjghx9NZSxCwVZpJGk24RFlQJ0GMRwqnJi9NDTKBHXXJdxNR72FQtu97bopinNNd6EnbqmwjpYjJ2gd4BNsFOsn8WhsNPsDBtkKWLjbKDRmwnMF7N2Gj7vokquQnceySvRapc/75L1tq6tX364r7vyr8zuxiJPPZ/3b6PUUPbA9B1ur357yPfYYNfeutd+exXnkKNOjfp3AxMCfeEH4e3wBn03w1u+IvxLeIueTSQom+EmWqRXd3PYgQTBHW7S60Cl76+kqwR0+yYWyleifkVbv/yQvzf5eBKXun029nPsj9hG7NPY1dhv+2b4jiKTSGMRr0kfSV9J30lfS99IP0CRbkg3pe+lW9KX0vVGR8bVNnfZ8Vxoith3b1tR8Tf1N+Aw5KR8WH5JTskvyK/IE76f3CH3ySPyUaocJsZLGJwX3EXHHH053ULVfJanm6GqjgvuBjZU4sq4SHpf15jBOlkfG2X9LA6FnWZn2CBL0d8Aa6SMDEdSkQSUyLFIPNIXGRN4L1/kKNXi9B1upLfIXfuPDXhAFdyzBzn6t1Fyu5fcmg3u8GUHdIYq5kVLXyg6yvFY7DUlYZoGV0bLWm+3ohqG4pZsxeI2t5Z4vhezc/OK6AMeZRGiK3Rw3eecN4BTfwLSXZ+brwJf2EDHqz7XFQWe/Ri4dlKrWkseh1DoR8AunDju/bUngfD9Wu3REaD1Q2D3g1rt77Vabfcz8t8Cbhj/AFkAcXgivILIAAAADklEQVQIW2NQ+8/wnwEABr4CJUS8tjcAAAAASUVORK5CYII="),
    radial-gradient(
        circle farthest-side at center -50px,
        rgb(40 215 61 / 20%) 0%,
        #333 100%
      )
      no-repeat,
    #333;
  font-size: 10px;
  line-height: 1.5em;
}

body {
  margin: 0;
  padding: 0 30px;
  height: 100%;
  min-height: 600px;
  background: none;
  color: var(--termgreen);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4286;
}

header,
#container,
footer {
  width: 100%;
}

header {
  padding: 18px 0 0;
  position: relative;
  margin-bottom: 1em;
}

header h2 {
  color: var(--termgreen-darker);
  font-size: 1.1em;
  text-transform: uppercase;
  margin-top: 0;
}

h1 {
  margin-top: 0;
  margin-bottom: 4px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  text-shadow: 1px 1px 1px #111;
  text-transform: uppercase;
  font-weight: 500;
}

h1 a {
  color: var(--termgreen);
}

h1 a:hover {
  color: inherit;
}

h2,
h3 {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  color: var(--termgreen);
}

#main {
  position: relative;
  height: 550px;
}

nav {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  right: 0;
}

nav a {
  color: var(--termgreen-darker);
}

nav a:hover {
  color: var(--termgreen);
}

nav ul {
  margin: 0;
  padding: 0;
  display: inline-block;
  border-right: 1px solid var(--termgreen);
}

nav li {
  display: inline-block;
  margin-right: 1rem;
}

nav #account {
  display: inline-block;
}

nav #account a {
  margin-left: 1rem;
}

nav form {
  margin-left: 1rem;
  display: inline-block;
}

nav form button {
  all: unset;
  color: var(--termgreen-darker);
  cursor: pointer;
}

nav form button:hover {
  color: var(--termgreen);
}

footer {
  border-top: 1px solid var(--termgreen-bit-darker);
  font-size: 0.8em;
  color: var(--termgreen-darker);
}

footer a {
  color: var(--termgreen-bit-darker);
  text-decoration: none;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}

footer li {
  display: inline-block;
  margin: 0 5px;
}

footer li.copy {
  float: right;
}

.btn {
  background: #333;
  display: inline-block;
  cursor: pointer;
  padding: 4px 12px;
  border: 1px solid var(--termgreen);
  color: var(--termgreen);
  margin-bottom: 0; /* For input.btn */
  font-size: inherit;
  line-height: inherit;
}

.btn:hover {
  background: var(--termgreen-bit-darker);
}

#upload_form {
  margin-bottom: 20px;
}

input[type="email"] {
  padding: 4px 12px;
  border: 1px solid var(--termgreen);
  background: rgb(0 0 0 / 30%);
  color: var(--termgreen);
  width: 250px;
  font-size: inherit;
  line-height: inherit;
}

button.back {
  position: absolute;
  bottom: 20px;
  left: 0;
}

.col {
  width: 50%;
  vertical-align: top;
  display: inline-block;
  padding: 10px 0 20px;
}

.col ul {
  color: var(--termgreen-bit-darker);
  padding: 0 0 0 20px;
}

.col #key {
  list-style-type: none;
  padding: 0;
  font-size: 14px;
  line-height: 14px;
}

.invisible {
  visibility: hidden;
}

small.label {
  background-color: var(--termgreen);
  color: black;
  border-radius: 2px;
  padding: 2px 5px;
  font-size: 8px;
  vertical-align: text-bottom;
  line-height: 16px;
  margin-left: 8px;
}

#paypal-button-container {
  margin: 30px auto;
  width: 450px;
  height: 100px;
}

@media (width <= 760px) {
  body {
    padding: 0 10px;
  }

  h1 {
    font-size: 2.2em;
    margin-top: 0.6em;
  }

  header h2 {
    font-size: 1em;
  }

  .col {
    width: 100%;
    display: block;
  }
}
